import React, {  } from 'react'
import { Team } from "src/assembled"
import { Footer } from 'src/components'
import { SEO } from "src/components/utils"
import { Navigation } from "src/assembled"


export default function TeamPage({ location }) {
    // seo
    const seo = <SEO
    title="Team"
    description="다어젠의 팀원들과 자문위원들을 소개합니다." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Team location={location} />
    </main>


    return <>
    <Navigation />
        {seo}
        {render}
        <Footer location={location} />
    </>
}
